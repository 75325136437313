import type { PayloadAction } from "@reduxjs/toolkit";
import type {
	DeleteRouteRequest,
	LocationResponse,
	RouteResponse,
	SosEventResponse,
} from "@somewear/api";
import { GetLocationsRequest, GetRoutesRequest } from "@somewear/api";
import { assetSlice } from "@somewear/asset";
import { signedOut } from "@somewear/auth";
import { grpc, someGrpc } from "@somewear/grpc";
import {
	fetchTrackingFilters,
	fetchTrackingSettings,
	fetchWorkspaceFilters,
} from "@somewear/messaging";
import type { ActionSetEpic, DateRange, IError, ISaveWaypointPayload } from "@somewear/model";
import {
	createActionSetEpicHandler,
	timestampFromMoment,
} from "@somewear/model";
import { UserDataSyncClient } from "@somewear/user-data-sync";
import { waypointActions } from "@somewear/waypoint";
import { noOp } from "@web/app/appSlice";
import type { RootState } from "@web/app/rootReducer";
import moment from "moment";
import type { Action } from "redux";
import type { Epic } from "redux-observable";
import { combineEpics, ofType } from "redux-observable";
import { asyncScheduler, forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, takeUntil, throttleTime } from "rxjs/operators";

import { trackingLocationActions } from "./locations/trackingLocationActions";
import { MapViewStore } from "./mapViewStore";
import {
	getLocationsFulfilled,
	initMapView,
	setDateFilter,
	startGeolocating,
	stopGeolocating,
} from "./trackingActions";
import type { ActiveUser, MapView } from "./trackingSlice";
import {
	apiLocationsError,
	setActiveTrackingUser,
	setTrackingFilters,
	setTrackingSettings,
	setWorkspaceFilters,
	updatedMapView,
} from "./trackingSlice";

/*
Get the users tracking sessions and dispatch the action to set it on the store
 */
/*
const apiTrackingIntervalsEpic: Epic<Action<string>> = (action$) =>
	action$.pipe(
		ofType(apiTrackingIntervalRequest.type),
		switchMap(() =>
			Api.getTrackingIntervals().pipe(
				mergeMap((intervalsResponse) => {
					return of(
						apiTrackingIntervalSuccess(intervalsResponse.toObject().intervalsList)
					);
				}),
				/!*mergeMap((intervalsResponse) =>
					from([
						addContactsFromResponses(intervalsResponse.toObject().intervalsList),
						apiTrackingIntervalSuccess(intervalsResponse.toObject().intervalsList),
					])
				),*!/
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiTrackingIntervalError(error)))
			)
		)
	);
*/

/*
@deprecated should be using grpc to get waypoints
Get the users tracking sessions and dispatch the action to set it on the store
 */
/*const apiRoutesEpic: Epic<Action<string>> = (action$) =>
	action$.pipe(
		ofType(apiRoutesRequest.type),
		switchMap(() =>
			Api.getRoutes().pipe(
				mergeMap((routeList) =>
					from([
						addContacts(
							routeList.toObject().routesList.mapNotNull((routes) => routes.owner)
						),
						apiRoutesSuccess(routeList.toObject().routesList),
					])
				),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiRoutesError(error)))
			)
		)
	);*/

/*
Get the locations for a user and dispatch the action to set it on the store
 */
/*const apiLocationsEpic: Epic<AnyAction, AnyAction> = (action$) =>
	action$.pipe(
		ofType(apiLocationsRequest.type),
		switchMap((action) =>
			Api.getLocations(action.payload).pipe(
				map((locationResponse) => {
					return apiLocationsSuccess(locationResponse.toObject().locationsList);
				}),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiLocationsError(error)))
			)
		)
	);*/

/*
type ApiLocationsFilterForUserInActions = PayloadAction<DateFilterPayload>;
type ApiLocationsFilterForUserOutActions =
	| PayloadAction<LocationResponse.AsObject[]>
	| PayloadAction<IError>;
const apiLocationsFilterForUser: Epic<
	ApiLocationsFilterForUserInActions | ApiLocationsFilterForUserOutActions,
	ApiLocationsFilterForUserOutActions
> = (action$) =>
	action$.pipe(
		ofType(setDateFilterForUser.type),
		map((action) => action as ApiLocationsFilterForUserInActions),
		mergeMap((action) =>
			Api.getLocations(
				action.payload.id,
				action.payload.filter && moment(action.payload.filter.start),
				action.payload.filter && moment(action.payload.filter.end)
			).pipe(
				map((locationResponse) => {
					return apiLocationsSuccess(locationResponse.toObject().locationsList);
				}),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiLocationsError(error)))
			)
		)
	);
*/

/*const apiLocationsResetFilterForUser: Epic<
	ApiLocationsFilterForUserInActions | ApiLocationsFilterForUserOutActions,
	ApiLocationsFilterForUserOutActions
> = (action$) =>
	action$.pipe(
		ofType(resetDateFilterForUser.type),
		map((action) => action as ApiLocationsFilterForUserInActions),
		mergeMap((action) =>
			Api.getLocations(
				action.payload.id,
				action.payload.filter && moment(action.payload.filter.start),
				action.payload.filter && moment(action.payload.filter.end)
			).pipe(
				map((locationResponse) => {
					return apiLocationsSuccess(locationResponse.toObject().locationsList);
				}),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiLocationsError(error)))
			)
		)
	);*/

type ApiLocationsFilterOutActions = PayloadAction<LocationResponse.AsObject[] | IError>;
const apiLocationsSetDateFilterEpic: Epic<
	PayloadAction<DateRange> | ApiLocationsFilterOutActions,
	ApiLocationsFilterOutActions,
	RootState
> = (action$, state$) =>
	action$.pipe(
		ofType(setDateFilter.type),
		map((action) => action as PayloadAction<DateRange>),
		switchMap((action) => of(action)),
		mergeMap((action) => {
			const from = moment(action.payload!.start);
			const to = moment(action.payload!.end);

			const locationsRequest = new GetLocationsRequest();
			locationsRequest.setFrom(timestampFromMoment(from));
			locationsRequest.setTo(timestampFromMoment(to));

			const routesRequest = new GetRoutesRequest();
			routesRequest.setFrom(timestampFromMoment(from));
			routesRequest.setTo(timestampFromMoment(to));

			return forkJoin({
				personalLocations: grpc
					.prepareRequestWithPayload(someGrpc.getLocations, locationsRequest)
					.pipe(map((location) => location.toObject().locationsList)),
				routeLocations: grpc
					.prepareRequestWithPayload(someGrpc.getRoutes, routesRequest)
					.pipe(
						map((routes) =>
							routes
								.toObject()
								.routesList.mapNotNull<LocationResponse.AsObject>((route) => {
									if (route.location === undefined) return undefined;
									return { ...route.location, userId: route.ownerId };
								})
						)
					),
			});
		}),
		map((response) => {
			const locationsList = response.personalLocations.concat(...response.routeLocations);
			return getLocationsFulfilled(locationsList);
		}),
		takeUntil(action$.pipe(ofType(signedOut.type))),
		catchError((error) => of(apiLocationsError(error)))
	);

const geoObs$ = new Observable<GeolocationPosition>((subscriber) => {
	console.log("start listening for locations");
	navigator.geolocation.getCurrentPosition(function (position) {
		console.log("Latitude is :", position.coords.latitude);
		console.log("Longitude is :", position.coords.longitude);
		subscriber.next(position);
	});
	navigator.geolocation.watchPosition(function (position) {
		console.log("Latitude is :", position.coords.latitude);
		console.log("Longitude is :", position.coords.longitude);
		subscriber.next(position);
	});
});

const startSosSessionEpic: ActionSetEpic<LocationResponse.AsObject, SosEventResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.startSosSession,
		(payload) => {
			return grpc.prepareRequestWithPayload(someGrpc.startSosSession, payload.data);
		}
	);
};

const resolveSosSessionEpic: ActionSetEpic<void, SosEventResponse.AsObject> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.resolveSosSession,
		(payload) => {
			return grpc.prepareRequest(someGrpc.resolveSosSession);
		}
	);
};

const startGeolocatingEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(startGeolocating.type),
		switchMap((action) => {
			console.log("get locations");
			return geoObs$.pipe(
				map((location) =>
					trackingLocationActions.shareLocation.request({ data: location })
				),
				throttleTime(5000, asyncScheduler, { leading: true, trailing: true })
			);
		}),
		takeUntil(action$.pipe(ofType(stopGeolocating.type))),
		catchError((error) => of(error))
	);

const shareGeolocationEpic: ActionSetEpic<GeolocationPosition, LocationResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.shareLocation,
		(payload) =>
			grpc
				.prepareRequestWithPayload(someGrpc.createTrackingLocations, payload.data)
				.pipe(map((r) => r.toObject().locationsList))
	);
};

const shareWaypointEpic: ActionSetEpic<GeolocationPosition, RouteResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.shareWaypoint,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.createWaypoint, payload.data)
	);
};

const saveWaypointEpic: ActionSetEpic<ISaveWaypointPayload, RouteResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		waypointActions.saveWaypoint,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.saveWaypoint, payload.data),
		(payload) => {
			return {
				onFulfilled: "",
				onPending: "Adding your new waypoint...",
				onRejected: "An error occurred creating your waypoint",
			};
		}
	);
};

const deleteWaypointEpic: ActionSetEpic<string, DeleteRouteRequest.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		waypointActions.deleteWaypoint,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.deleteWaypoint, payload.data),
		(payload) => {
			return {
				onFulfilled: "Successfully deleted the waypoint",
				onPending: "Deleting your waypoint...",
				onRejected: "An error occurred deleting your waypoint",
			};
		}
	);
};

const fetchTrackingFiltersEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(fetchTrackingFilters.type),
		switchMap(() => {
			return UserDataSyncClient.getTrackingFilters().pipe(
				map((filters) => setTrackingFilters(filters)),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError(() => of(noOp()))
			);
		})
	);

const fetchTrackingSettingsEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(fetchTrackingSettings.type),
		switchMap(() => {
			return UserDataSyncClient.getTrackingSettings().pipe(
				map((filters) => setTrackingSettings(filters)),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError(() => of(noOp()))
			);
		})
	);

const fetchWorkspaceFiltersEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(fetchWorkspaceFilters.type),
		switchMap(() => {
			return UserDataSyncClient.getWorkspaceFilters().pipe(
				map((filters) => setWorkspaceFilters(filters)),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError(() => of(noOp()))
			);
		})
	);

const initMapViewEpic: Epic<Action, PayloadAction<MapView> | Action> = (action$) =>
	action$.pipe(
		ofType(initMapView.type),
		switchMap(() => {
			return MapViewStore.read();
		}),
		mergeMap((view) => {
			if (view === undefined) return of(noOp());
			return of(updatedMapView(view));
		})
	);

const toggleVisibilityOverrideOnActiveTrackingUserEpic: Epic = (action$) => {
	return action$.pipe(
		ofType(setActiveTrackingUser.type),
		map((action: PayloadAction<ActiveUser>) => {
			return assetSlice.actions.setUserVisibility({
				userId: action.payload.userId,
				visibility: undefined,
			});
		})
	);
};

export const trackingEpics = combineEpics<any, any, any>(
	apiLocationsSetDateFilterEpic,
	startSosSessionEpic,
	resolveSosSessionEpic,
	startGeolocatingEpic,
	shareGeolocationEpic,
	shareWaypointEpic,
	saveWaypointEpic,
	deleteWaypointEpic,
	fetchTrackingFiltersEpic,
	fetchTrackingSettingsEpic,
	fetchWorkspaceFiltersEpic,
	initMapViewEpic,
	toggleVisibilityOverrideOnActiveTrackingUserEpic
);
